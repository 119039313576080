/* styles.css */

/* General styles */
.submit-picks-heading {
  font-size: 24px;
  font-weight: bold;
}

.column-labels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  gap: 10px;
  margin-bottom: 10px;
}

.label {
  font-weight: bold;
  text-align: center;
}

/* Styling for the matchup row */
.matchup-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px; /* Add margin between rows */
}

.started {
  background-color: rgb(25, 26, 26);
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.team-logo {
  width: 40px; /* Decrease logo size */
  height: 40px; /* Decrease logo size */
}

.team-name {
  font-size: 10px; /* Smaller font size */
  margin-top: 3px; /* Smaller margin top */
}

.spread {
  text-align: center;
  font-size: 16px; /* Smaller font size */
}

.over-under {
  text-align: center;
  font-size: 16px; /* Smaller font size */
  cursor: pointer;
}

.selected {
  background-color: #4caf50;
  color: white;
}

/* Over/Under options container */
.over-under-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px; /* Add margin at the top */
  margin-left: 10px;
}

/* Over and Under buttons */
.over,
.under {
  padding: 6px 6px; /* Smaller padding */
  cursor: pointer;
}

/* Style for selected over and under buttons */
.over.selected,
.under.selected {
  background-color: #4caf50;
  color: #fff;
}

/* Submit button */
.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #b3b3b3;
  cursor: not-allowed;
}

/* Login button style */
.login-button {
  padding: 0.5rem 1rem;
  background-color: rgb(255, 207, 119);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--dl-color-gray-black);
  cursor: pointer;
  box-shadow: 5px 6px 0px 0px #000000;
  transition: 0.3s;
}

.login-button:hover {
  opacity: 0.8;
}

.login-button {
  margin-bottom: 20px;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;  /* Transition effect for opacity changes */
}
.modal-background.active {
  opacity: 1;
}

.modal-content {
  background-color: #fff;
  color:rgba(0, 0, 0);
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transition: opacity 0.6s ease-in-out 0.1s;  /* Transition effect for opacity changes with a delay */
}

.modal-background.active .modal-content {
  opacity: 1;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.share-button {
  padding: 15px 30px;      /* Increased padding */
  font-size: 18px;         /* Larger font size */
  border: none;
  background-color: #007BFF;  /* Brighter color */
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  width: 80%;
  transition: background-color 0.3s;  /* Smooth transition for hover effect */
}

.share-button:hover {
  background-color: #0056b3;  /* Darkened color for hover */
}
