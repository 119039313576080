.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem; /* Adjust the padding to have more space horizontally */
  background-color: #27272D;
  color: white;
  max-width: 1400px; /* Set a maximum width for the navbar */
  width: 80%;
}

.navbar-logo-container {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: auto;
  margin-right: 10px; /* optional, adds some space between the logo and the text */
}

.navbar-logo {
  font-size: 1.5rem;
  font-weight: 600;
}

.navbar-buttons {
  display: flex;
  align-items: center;
}

.navbar-button {
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 1rem;
}

.navbar-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.navbar-icon {
  width: 20px;
  height: 20px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.navbar-icon img {
  width: 100%;
  height: 100%;
}

.navbar-button.login {
  background-color: rgb(255, 207, 119);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 5px 6px 0px 0px #000000;
  transition: 0.3s;
  color: var(--dl-color-gray-black);
}

.navbar-button.login:hover {
  opacity: 0.5;
}

/* Responsive styles */
@media (max-width: 767px) {
  .navbar-container {
    flex-direction: column;
    padding: 1rem 1rem; /* Adjust padding for smaller screens */
  }
  
  .navbar-logo-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 30px; /* smaller logo */
    margin-right: 5px; /* less spacing */
  }

  .navbar-logo {
    font-size: 1.2rem; /* smaller text */
  }

  .navbar-buttons {
    margin-top: 1rem;
  }

  .navbar-button {
    margin: .8rem;
    margin-bottom: 0.5rem;
    width: 100%;
    text-align: center;
  }

  .navbar-icon img {
    width: 80%;
    height: 80%;
    margin: 1rem;
  }

  .navbar-button.login {
    width: 50%;
    font-size: .6rem;
  }
}
