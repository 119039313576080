/* profile.css */

.profile-container {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.profile-p {
  font-size: 1.2rem;
  padding: 1.2rem;
  color: white;
}

.profile-header {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  white-space: normal;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.record-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.record-card {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin: 10px 5px;
  text-align: center;
  background-color: #f9f9f9;
}

.profile-weekly-picks-section {
  width: 100%;
  display: flex;
  flex-direction: column; /* Makes the weekly pick cards stack vertically */
  gap: 10px; /* Provides consistent spacing between each week's card */
  text-align: center;
}

h3 {
  margin-bottom: 5px;
}

.profile-weekly-pick {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 100%; /* Each week's card will take the full width of its container */
}

.profile-team-logos {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.profile-picks-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

/* Styling for favorite and underdog picks */
.profile-pick-favorite, .profile-pick-underdog, .profile-pick-lock {
  width: 80px; /* Adjust to make the logo fit well */
  height: auto;
}

/* Grouping for over picks */
.profile-pick-over {
  display: flex;
  align-items: center;
}

/* Grouping for under picks */
.profile-pick-under {
  display: flex;
  align-items: center;
}

/* Styling for over and under picks */
.profile-pick-over-home, .profile-pick-over-away, .profile-pick-under-home, .profile-pick-under-away {
  width: 40px; /* Making them smaller than favorite and underdog */
  height: auto;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .profile-header {
    flex-direction: column;
  }

  .profile-pic {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .profile-record-section {
    flex-direction: column;
  }

  /* Styling for favorite and underdog picks */
  .profile-pick-favorite, .profile-pick-underdog, .profile-pick-lock {
    width: 60px; /* Adjust to make the logo fit well */
    height: auto;
  }

  .profile-pick-over-home, .profile-pick-over-away, .profile-pick-under-home, .profile-pick-under-away {
    width: 30px;
    height: auto;
  }
}
