.card {
  gap: 32px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 96px 64px 32px;
  flex-direction: column;
  background-color: #8FA7DF;
}

.card-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-image {
  width: 100%;
  max-width: 386px;
  height: auto;
}

.card-content {
  gap: 16px;
  width: 100%;
  max-width: 460px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.card-header {
  color: #000000;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
}

.card-description {
  color: #000000;
  font-size: 18px;
  line-height: 27px;
}

.card-learn {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px; /* Adds some padding around the text and the arrow */
  border: 2px solid #000000; /* Makes the border slightly thicker */
  border-radius: 4px; /* Rounds the corners for a softer appearance */
  background-color: transparent;
  color: #000000;
  text-decoration: none; /* Remove underline if it's an anchor tag */
  transition: all 0.3s ease; /* Makes hover transitions smoother */
  box-shadow: none;
}

.card-learn:hover {
  background-color: #cdd1ff; /* Slight color change on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
  border-color: #333333; /* Darker border on hover */
}

.card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  gap: 15px;
}

.card-image-arrow {
  width: 16px;
  object-fit: cover;
}

@media (min-width: 768px) {
  .card {
    flex-direction: row;
    align-items: center;
  }

  .card-image-container {
    min-width: 386px; /* Add min-width to ensure the image is always visible */
    flex: 1;
  }

  .card-content {
    max-width: 100%;
  }
}
