/* leagueList.css */

.league-list-container {
  margin: 10px;
}

@media screen and (min-width: 768px) {
  .league-list-container {
    min-width: 650px; 
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .league-header .styled-select:nth-child(2) {
    max-width: 200px; /* Adjust as needed */
  }
}

.select-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.league-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  border-radius: 4px;
}

.league-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.styled-select {
  border: 1px solid #ccc; /* subtle border */
  font-size: 1.5em;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  padding: 10px; /* added padding */
  position: relative; /* added for absolute positioning of arrow */
}

/* Arrow Icon */
.styled-select::after {
  content: '▼';  /* down arrow */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;  /* ignore clicks and touches */
}

.styled-select:focus {
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
}

/* Add media query for responsiveness */
@media (max-width: 768px) { /* This is for tablets and below */
  .league-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .league-info p {
    margin-top: 5px;
  }
  
  .styled-select {
    width: 100%;
    margin-bottom: 10px;
  }
}

.leave-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 4px 8px; /* Reduced padding */
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 4px; /* Keep some space above the button */
}

.leave-button:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.download-button, .share-button {
  background-color: #00b345;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 4px 8px; /* Reduced padding */
  font-size: 14px; /* Smaller font size */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 4px; /* Keep some space above the button */
}

.download-button:hover, .share-button:hover {
  background-color: #007e35;
  transform: scale(1.05);
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: #f7f7f7;
  border-radius: 4px;
}

th, td {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #fff;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}


/* Style the disabled option */
option[disabled] {
  color: #999;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  background-color: #fff;
  border-radius: 4px;
}

.pagination button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-weight: bold;
}


/* Style for the confirmation dialog */
/* The ::-webkit-dialog pseudo-element targets the Chrome browser */
::-webkit-dialog {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

::-webkit-dialog-title {
  font-size: 18px;
  padding: 15px;
  background-color: #f0f0f0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

::-webkit-dialog-message {
  font-size: 16px;
  padding: 15px;
}

::-webkit-dialog-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

::-webkit-dialog-button {
  font-size: 16px;
  padding: 8px 15px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
}

::-webkit-dialog-button:hover {
  background-color: #0056b3;
}

/* Add these styles to your existing leagueList.css file */

/* Container for the 4 pick groups */
.picks-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

/* Styling for favorite and underdog picks */
.pick-favorite, .pick-underdog, .pick-lock {
  width: 50px; /* Adjust to make the logo fit well */
  height: auto;
}

/* Grouping for over picks */
.pick-over {
  display: flex;
  align-items: center;
}

/* Grouping for under picks */
.pick-under {
  display: flex;
  align-items: center;
}

/* Styling for over and under picks */
.pick-over-home, .pick-over-away, .pick-under-home, .pick-under-away {
  width: 30px; /* Making them smaller than favorite and underdog */
  height: auto;
}

.table-section td:first-child {
  font-size: 1em; /* Adjust as needed */
  max-width: 150px; /* Adjust based on your design needs */
  overflow: hidden;
}

/* Explicitly set the width of W and L columns */
th.wl-column, td.wl-column {
  width: 40px;
}

/* Set minimum width for pick summary columns */
th.pick-summary, td.pick-summary {
  min-width: 160px;
}

.won {
  background-color: #80d7a1cb;
}

.lost {
  background-color: rgb(224, 82, 82);
}

.pushed {
  background-color: #6e6e6e;
}

@media (max-width: 768px) { 
  .actions-column {
    display: none;
  }

  .download-button {
    display: none;
  }

  .table-section table {
    width: 100%;
    table-layout: fixed;
    overflow-x: auto;
  }

  .table-section th, .table-section td {
    word-wrap: break-word;
  }

  /* Adjust size of favorite and underdog picks */
  .pick-favorite, .pick-underdog, .pick-lock {
    width: 35px;
  }

  /* Adjust size of over and under picks */
  .pick-over-home, .pick-over-away, .pick-under-home, .pick-under-away {
    width: 20px;
  }

  /* Stack over and under logos on top of each other */
  .pick-over, .pick-under {
    flex-direction: column;
  }
  
  /* Explicitly set the width of W and L columns */
  th.wl-column, td.wl-column {
    width: 40px;
  }

  .table-section td:first-child {
    font-size: .9em;
    overflow: hidden;
  }
}