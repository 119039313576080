.hero-hero {
  gap: 80px;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding: 64px 96px;
  flex-direction: column;
  padding-bottom: 96px;
}

.hero-heading {
  gap: 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hero-header {
  color: rgb(255, 255, 255);
  font-size: 90px;
  max-width: 900px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 108px;
}

.hero-caption {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 800px;
  text-align: center;
  line-height: 36px;
}

.hero-buttons {
  gap: 48px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.hero-learn {
  color: rgb(255, 255, 255);
}

@media (max-width: 767px) {
  .hero-hero {
    padding: 24px 12px;
  }

  .hero-heading {
    gap: 8px;
  }

  .hero-header {
    font-size: 36px;
    max-width: 70%;
    line-height: 43px;
  }

  .hero-caption {
    font-size: 16px;
    line-height: 24px;
  }
}
