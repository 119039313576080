.footer-footer {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-sixunits);
}

.footer-main {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.footer-logo {
  color: #ffffff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}

.footer-twitter {
  text-decoration: none;
}

.footer-image {
  width: 32px;
  object-fit: cover;
}

.footer-link {
  color: #ffffff;
}

.footer-copyright {
  color: #ffffff;
  font-size: 12px;
}

@media (max-width: 767px) {
  .footer-footer {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .footer-main {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-logo {
    font-size: 20px;
    line-height: 24px;
  }
  .footer-twitter {
    display: flex;
  }
}
