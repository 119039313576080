/* submitpicks.css */

/* Container for the SubmitPicks page */
.submit-picks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  gap: 1rem;
  background-color: #27272D;
  color: white;
  min-height: 100vh;
}

/* Main content area */
.submit-picks-content {
  max-width: 600px;
  text-align: center;
}

/* Heading style */
.submit-picks-heading {
  font-size: 2rem;
  font-weight: bold;
}

/* Paragraph style */
.submit-picks-paragraph {
  font-size: 1.2rem;
  padding: 1.2rem;
}

.pick-summary {
  margin-bottom: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  border: 6px solid #f3f3f3;  /* Light grey */
  border-top: 6px solid #3498db;  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin: 20px auto;  /* Center the spinner */
}