/* pickSummary.css */

/* Pick summary container */
.pick-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #27272D;
  color: #333;
}

.pick-summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  margin-inline: 7px;
}

.pick-summary-team .team-logo {
  width: 75px; /* Increase the width and height */
  height: 75px;
  border-radius: 50%;
}

.pick-summary-team .team-logo-over-under {
  width: 40px; /* Reduce the width and height */
  height: 40px;
  border-radius: 50%;
}

/* Pick Summary label */
.pick-summary-label {
  font-weight: bold;
  color: rgb(255, 207, 119); /* Use the same color as the submit-picks-button */
}

/* Container to group pick summary items in a row */
.pick-summary-row {
  display: flex;
  align-items: center;
  gap: 20px; /* Add some spacing between pick summary items in a row */
}

.pick-summary-text {
  color: #FFF;
}

.game-summary {
  color: #FFF;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
}